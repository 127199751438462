import React, { useState } from "react";
import { Suspense } from "react";
import { Engine, Scene, Model } from "react-babylonjs";
import { Vector3, Color3 } from "@babylonjs/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExploreIcon from "@material-ui/icons/Explore";
import Divider from '@material-ui/core/Divider';

import "./App.css";

const DefaultPlayground = () => {
  const [loading, setLoading] = useState(true);
  return (
    <div
      style={{
        backgroundColor: "#262626",
        width: "100%",
        minHeight: "100vh",
        textAlign: "center",
      }}
    >
      {loading && (
        <CircularProgress color="secondary" style={{ marginTop: 30 }} />
      )}
      <div
        style={{
          flex: 1,
          display: "flex",
          flexAlign: "center",
          alignItems: "center",
          justifyItems: "center",
          textAlign: "center",
          flexWrap: "wrap",
        }}
      >
        <Suspense>
          <Engine antialias canvasId="babylonJS">
            <Scene clearColor={new Color3(0.15, 0.15, 0.15)}>
              <arcRotateCamera
                name="camera"
                alpha={10}
                beta={0}
                radius={300}
                target={new Vector3(10, 150, 10)}
                setPosition={[new Vector3(-300, 400, 50)]}
                lowerBetaLimit={0.1}
                upperBetaLimit={(Math.PI / 2) * 0.99}
                lowerRadiusLimit={200}
                upperRadiusLimit={400}
              />

              <hemisphericLight
                name="light1"
                intensity={2}
                direction={new Vector3(30, 20, 10)}
              />

              <Model
                rotation={new Vector3(0, 399.98, 0)}
                position={new Vector3(0, 200, 0)}
                rootUrl={`/`}
                onModelLoaded={() => setLoading(false)}
                sceneFilename="bucovina.glb"
                scaling={new Vector3(50, 50, 50)}
              />
            </Scene>
          </Engine>
        </Suspense>
      </div>

      <div style={{ width: "100%" }}>
        <Container style={{ textAlign: "left", paddingTop: 30 }} maxWidth="md">
          <div style={{ display: "flex" }}>
            <ExploreIcon
              style={{ color: "white", marginRight: 10, marginTop: 4 }}
            />
            <Typography
              style={{ color: "white", fontWeight: 700, opacity: 0.9 }}
              variant="h5"
            >
              Bucovina
            </Typography>
          </div>
          <Typography style={{ color: "white", opacity: 0.9 }} variant="h6">
            hartă interactivă a formelor de relief
          </Typography>
          <Divider style={{ backgroundColor: "#444", marginTop: 20 }} />
          <Typography
            style={{
              color: "white",
              opacity: 0.9,
              fontWeight: 700,
              marginTop: 20,
              marginBottom: 20,
            }}
            variant="body1"
          >
            Legenda:
          </Typography>
          <Typography style={{ color: "white", opacity: 0.9 }} variant="body1">
            1. Suceava, 2. Rădăuți, 3. Fălticeni, 4. Gura Humorului, 5.
            Câmpulung Moldovenesc, 6. Vatra Dornei
          </Typography>

          <Divider
            style={{ backgroundColor: "#444", marginTop: 20, marginBottom: 30 }}
          />
          <Typography
            style={{
              color: "white",
              opacity: 0.9,
              fontWeight: 700,
              marginTop: 20,
              marginBottom: 20,
            }}
            variant="body1"
          >
            Descriere:
          </Typography>

          <Typography style={{ color: "white", opacity: 0.9 }} variant="body1">
            Bucovina înseamnă munți și câmpie, înseamnă râuri și păduri,
            înseamnă români, nemți, romi, ucraineni, evrei, și huțuli care
            trăiesc în aceste locuri, în pace, de sute de ani. Sperăm că această
            hartă să ajute la o înțelegere mai bună a geografiei care definește
            regiunea și obiceiurile sale frumoase.
          </Typography>
          <br/>
          <Typography style={{ color: "white", opacity: 0.9 }} variant="body1">
            Bucovina (în germană Buchenland = Țara fagilor), devenită Ducatul
            Bucovinei în Imperiul Austriac (germană Herzogtum Bukowina), este o
            regiune istorică ce cuprinde un teritoriu de 10.441 km², împărțit în prezent între România și Ucraina. Partea din România coincide în mare parte județului Suceava. Partea de vest este ocupată de nordul Carpaților Orientali, cu văile râurilor Moldova și Bistrița, care își continuă drumul către câmpie, în partea de răsărit a județului. 
          </Typography>

          <Divider
            style={{ backgroundColor: "#444", marginTop: 30, marginBottom: 30 }}
          />
          <a href="https://bucovina.shop">
            <img
              src="/logob.png"
              alt=""
              style={{ height: 18, marginBottom: 20 }}
            />
          </a>
        </Container>
      </div>
    </div>
  );
};

export default DefaultPlayground;
